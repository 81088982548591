import React from 'react';

import UI from '#front-credpago';

import { RebrandingWrapper } from './style';

const RebrandingCommunication = () => {
  return (
    <RebrandingWrapper>
      <UI.Row>
        <UI.Col>
          <span>
            CredPago é Loft /Fiança Aluguel <span className="span-link-loft">-</span> Saiba mais{' '}
            <a href="https://loft.com.br/" className="href-link-loft">
              www.loft.com.br
            </a>
          </span>
        </UI.Col>
      </UI.Row>
    </RebrandingWrapper>
  );
};

export default RebrandingCommunication;
