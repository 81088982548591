import styled from 'styled-components';

import UI from '#front-credpago';

export const HeaderWrapper = styled.div`
  z-index: 2000;

  .header {
    margin-top: 58px;

    @media ${UI.globals.mediaqueries.xs} {
      margin-top: 112px;
    }

    @media ${UI.globals.mediaqueries.sm} {
      margin-top: 76px;
    }

    padding-top: 20px;
    padding-bottom: 20px;

    @media ${UI.globals.mediaqueries.xs}, ${UI.globals.mediaqueries.sm} {
      padding-top: 10px;
      padding-bottom: 10px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px 0px !important;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1200px) {
      nav ul li {
        margin-left: 1em;
      }

      aside {
        > span {
          margin-right: 1em !important;
        }

        .has-text-below {
          min-width: initial;
        }
      }
    }
  }

  .text-below-signin {
    transition: all 0.15s;
  }

  &.active .header .text-below-signin {
    opacity: 0;
  }
`;
