import React from 'react';

import Avatar from '../../images/whatsapp/avatar.png';

const ChatButton = () => {
  return (
    <a
      href={'https://api.whatsapp.com/send?phone=554191488056'}
      title={'Fale conosco'}
      target="_blank"
      rel="noreferrer"
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        cursor: 'pointer',
        zIndex: '2147483647',
        backgroundImage: Avatar,
      }}
    >
      <img src={Avatar} />
    </a>
  );
};

export default ChatButton;
